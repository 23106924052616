import React from "react";

import style from "./Button.module.css";

type ButtonProps = {
  text: string;
  onClick?: () => void;
}


const Button: React.FC<ButtonProps> = ({text, onClick}) => {
  return (
    <button className={style.button_yellow} type='submit' onClick={onClick} >
      {text}
    </button>
  );
};

export default Button;