import { useSelector } from "react-redux";
import { selectUser, selectIsLoggedIn, selectIsRefreshing, selectToken } from "../redux/auth/selectors";

interface User {
    id: string;
    name: string | null;
    email: string | null;
    phone: string | null;
    isAdmin: boolean;
}

interface AuthType {
    isLoggedIn: boolean | unknown;
    isRefreshing: boolean | unknown;
    user: User;
    token: string;
}

export default function useAuth(): AuthType {
    const isLoggedIn = useSelector<boolean>(selectIsLoggedIn);
    const isRefreshing = useSelector<boolean>(selectIsRefreshing);
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    return {
        isLoggedIn,
        isRefreshing,
        user,
        token,
    }
}