import React from "react";

import style from './Title.module.css';

type TitleProps = {
  text: string
}


const Title: React.FC<TitleProps> = ({ text }) => {
  return (
      <h2 className={style.title}>{text}</h2>
  );
};

export default Title;