import React from "react";

import getDate from "../../utils/getDate";
// import ContainerWrap from "../ContainerWrap";
import { MyrequestsProps, OwnRequestsType } from "./interfaces";

import style from "./MyRequests.module.css";
import sprite from "../../images/sprite.svg";


const MyRequests: React.FC<MyrequestsProps> = ({ownRequests}) => {
  
  return (
    // <ContainerWrap>

      <ul className={style.list}>
        {ownRequests.map((request: OwnRequestsType) => {
          return (
            <li className={style.container} key={request._id}>
              {request.createdAt && (
                <div className={style.date}>
                   <svg className={style.icon} >
                  <use href={sprite + '#calendar'}></use>
                  </svg>
                 <p>{getDate(request.createdAt)}</p>
                </div>
                
              )}
              <p>{request.question}</p>
            </li>
          )
        })}
      </ul>
    // </ContainerWrap>
  );
};

export default MyRequests;