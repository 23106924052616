import React from "react";

import { User } from "../UsersList/interfaces";
import { UsersItemProps } from "../../interfaces/interfaces";

import style from "./UsersListItem.module.css";


const UsersListItem: React.FC<UsersItemProps<User>> = ({ user }) => {
 
  return (
    <li key={user._id} className={style.container}>
      <p className={style.item}><span className={style.category}>Ім'я:</span> {user.name}</p>
      <p className={style.item}><span className={style.category}>Телефон:</span> {user.phone}</p>
      <p className={style.item}><span className={style.category}>Email:</span> {user.email}</p>
      <p className={style.item}><span className={style.category}>Admin:</span> {user.isAdmin? 'Yes' : 'No'}</p>
    </li>
  );
};

export default UsersListItem;