import React, {useState} from "react";

import ContainerWrap from "../../components/ContainerWrap";
import UsersListItem from "../../components/UsersListItem";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { User } from "./interfaces";
import { UsersListProps } from "../../interfaces/interfaces"; 

import style from "./UsersList.module.css";


const UsersList: React.FC<UsersListProps<User>> = ({ users }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [displayCount, setDisplayCount] = useState<number>(5);

  const filteredUsers = users.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    setDisplayCount(filteredUsers.length);
  };

  return (
    <ContainerWrap>
      <Title text={'Список зареєстрованих користувачів'} />
      <div className={style.inputWrapper}>
      <label htmlFor="query">Пошук користувача за ім'ям, email, телефоном:</label>
      <input
        type="text"
        id="query"
        placeholder="Почніть вводити текст"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={style.input}
        aria-label="user search"
      />
      </div>
      <ul className={style.list}>
        {filteredUsers.slice(0, displayCount).map((user) => (
          <UsersListItem key={user._id} user={user} />
        ))}
      </ul>
      {displayCount < filteredUsers.length && (
        <Button 
          text="Завантажити ще" 
          onClick={handleLoadMore}
          aria-label="Load more users"
        />
      )}
    </ContainerWrap>
  );
};

export default UsersList;