import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "../../redux/auth/selectors";
import { useAppDispatch } from "../../redux/hooks";
import { getOwnRequests } from "../../redux/requests/operations";
import { selectOwnRequests } from "../../redux/requests/selectors";
import { selectOwnOpinions } from "../../redux/opinions/selectors";
import { deleteOpinion, getOwnOpinionsByCategory } from "../../redux/opinions/operations";
import OpinionModalToggle from "../../components/OpinionModalToggle";
import MyRequests from "../../components/MyRequests/MyRequests";
import ContainerWrap from "../../components/ContainerWrap";
import UserData from "../../components/UserData/UserData";
import OpinionList from "../../components/OpinionList";
import ButtonUp from "../../components/ButtonUp";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Modal from "../../components/Modal";
import { OpinionsCategory, DeleteType } from "../../interfaces/interfaces";

import style from "./UserPage.module.css";
import TourRequest from "components/TourRequest";


const UserPage: React.FC = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOwnRequests());
    dispatch(getOwnOpinionsByCategory(OpinionsCategory.HOTELS))
  }, [dispatch]);
  
  const user = useSelector(selectUser);
  const ownRequests = useSelector(selectOwnRequests);
  const ownOpinions = useSelector(selectOwnOpinions)

  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalRequestActive, setModalRequestActive] = useState<boolean>(false);


  const openModal = () => {
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const openModalRequest = () => {
    setModalRequestActive(true);
  }

  const closeModalRequest = () => {
    setModalRequestActive(false);
  }


  const handleDelete = async (dataForDelete: DeleteType) => {
    await dispatch(deleteOpinion(dataForDelete));
  };

  return (
    <ContainerWrap>{
      user && (
        <>
          <div className={style.userDataContainer}>
            <UserData user={user} />
            <div className={style.buttons}>
              <Button 
                text="Залишити відгук" 
                onClick={openModal}
                aria-label="to write the opinion"
              />
              <button className={style.button_white} 
                onClick={openModalRequest}
                // href="/tourdream-app#name" 
                aria-label="to send the request"
              >
                Зв'язатися
              </button>
            </div>
          </div>
          {ownRequests && (
            <div className={style.wrapper}>
            <Title text={'Мої запити'} />
              <MyRequests ownRequests={ownRequests} />
            </div>
          )}
          {ownOpinions && (
            <div className={style.wrapper}>
              <Title text={'Мoї відгуки'} />
              <OpinionList 
                opinions={ownOpinions} 
                onDelete={handleDelete} 
                isAdmin={user.isAdmin}
              />
            </div>
            
          )}
          {modalActive && 
            (<Modal isOpen={modalActive} onClose={closeModal}>
              <OpinionModalToggle onClose={closeModal}/>
            </Modal>)
          }

          {modalRequestActive && 
            (<Modal isOpen={modalRequestActive} onClose={closeModalRequest}>          
            <TourRequest />            
            </Modal>)
          }
        </>
      )}
      <ButtonUp />
    </ContainerWrap>
  );
};

export default UserPage;