import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([^0-9]*)$/gm, "Ввести можливо тільки букви")
      .required("Введіть ваше ім`я"),
    email: Yup.string()
      .email("Не вірна електронна адреса")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Введіть електронну адресу у форматі your@mail.com"
      )
      .required("Необхідно ввести електронну адресу"),
    phone: Yup.string()
      .matches(
        /^\+380\d{2}\d{7}$/,
        "Введіть номер телефону у форматі +380501231212"
      )
      .required("Необхідно ввести номер телефону"),
    question: Yup.string().required("Введіть ваше питання"),
  });