import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import DatePicker from "react-date-picker";
import { Rating } from "react-simple-star-rating";

import { selectIsLoggedIn, selectUserId, selectUserName } from "../../redux/auth/selectors";
import { addOpinion } from "../../redux/opinions/operations";
import { useAppDispatch } from "../../redux/hooks";
import changePhotos from "../../utils/changePhotos";
import deletePhotos from "../../utils/deletePhotos";
import countFiles from "../../utils/countFiles";
import { Values, ValuesSimple } from "./interfaces";
import { ModalProps, PhotoObject, OpinionsCategory } from "../../interfaces/interfaces";
import { validationSchemaHotel, validationSchemaSimpleHotel } from "../../schemas/opinions";

import countries from "../../data/countries.json";
import "../../index.css";
import style from "./OpinionFormHotel.module.css";
import sprite from "../../images/sprite.svg";


const OpinionForm: React.FC<ModalProps> = ({onClose}) => {
  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);
  const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

  const [photosFromForm, setPhotosFromForm] = useState<File[]>([]);
  const [photosUrlFromForm, setPhotosUrlFromForm] = useState<PhotoObject[]>([]);

  const n: number = photosFromForm.length;

  const onChangeFile = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    const n = e.target.files?.length;
    const files = e.target.files;
    
    if (n && files) { 
      const { fileArray, fileUrlArray } = changePhotos(files, n);

      setPhotosFromForm([
        ...photosFromForm,
        ...fileArray
      ]);
      setPhotosUrlFromForm([
        ...photosUrlFromForm,
        ...fileUrlArray
      ]);
    }
  }

  const handleDeletePhoto = ( item: PhotoObject ) => {
    const { urlArrayAfterDeleting, fileArrayAfterDeleting } = deletePhotos(item, photosFromForm, photosUrlFromForm);
    
    setPhotosUrlFromForm([
      ...urlArrayAfterDeleting
    ]);
    setPhotosFromForm([
      ...fileArrayAfterDeleting 
    ]);
  }

  useEffect(() => {
    countFiles(photosUrlFromForm);
  }, [photosUrlFromForm])

  return (
    <div className={style.formWrap}>
      {(isLoggedIn && userName) ? (
        <Formik<ValuesSimple>
        initialValues={{
          category: OpinionsCategory.HOTELS,
          country: "",
          hotel: "",
          date: "",
          opinion: "",
          rate: "0",
          photos: [],
        }}
        onSubmit={async (values, { resetForm }: FormikHelpers<ValuesSimple>) => {
          const { date, country, hotel, rate, opinion, category } = values;
          const formWithData = new FormData();
          
          if (n) {
            for (let i=0; i<n; i++){
              formWithData.append("photos", photosFromForm[i]);
            }
          }
          formWithData.append("dateOfVisit", date.toString());
          formWithData.append("country", country);
          formWithData.append("hotel", hotel);
          formWithData.append("rate", rate.toString());
          formWithData.append("category", category);
          formWithData.append("opinion", opinion);
          formWithData.append("userId", userId);
          formWithData.append("userName", userName);

          await dispatch(addOpinion(formWithData));
    
          resetForm();
          onClose();
        }}
          validationSchema={validationSchemaSimpleHotel}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={false}
        >
          {({ errors, touched, handleSubmit, values, handleChange, setFieldValue }) => {

            return (
              <Form className={style.form} autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className={style.inputField}>
                  <DatePicker
                        name="date"
                        value={values.date}
                        calendarAriaLabel="Toggle calendar"
                        format="y-MM"
                        locale="uk-UK"
                        required
                        maxDetail="year"
                        onChange={(date) => {
                          setFieldValue("date", date)
                        }}
                  />
                  <div className={style.inputWrapper}>
                    <label
                      id="labelCountry"
                      className={style.labelCountry}
                      htmlFor="country"
                    >
                      Оберіть країну
                    </label>
                    <ErrorMessage
                      name="country"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      as="select" 
                      name="country"
                      id="country"
                      aria-label="select country"
                      value={values.country}
                      onChange={handleChange}
                      required
                      className={style.country}
                    >
                      <option value="default"> </option>
                      {countries.map(item => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                    </Field>
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="hotel"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      className={`${style.input} ${
                        errors.opinion && touched.opinion ? style.inputError : ""}`}
                      type="text"
                      placeholder="Назва готелю"
                      aria-label="hotel name"
                      name="hotel"
                      id="hotel"
                      value={values.hotel}
                      onChange={handleChange}
                    />
                  </div>            
                  <div className="inputWrapper">
                    <ErrorMessage
                      name="opinion"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      as="textarea"
                      className={`${style.input} ${
                        errors.opinion && touched.opinion ? style.inputError : ""
                      } ${style.inputQuestion}`}
                      placeholder="Відгук про відпочинок"
                      name="opinion"
                      aria-label="opinion"
                      id="opinion"
                      value={values.opinion}
                      onChange={handleChange}
                    />
                  </div>
                  {photosUrlFromForm && (
                    <ul className={style.photoList}>
                      {photosUrlFromForm.map((item) => {
                        return (
                          <li className={style.photoWrap} key={item.fileBlobUrl}>
                              <img className={style.photo} src={item.fileBlobUrl} alt="vacation"/>
                            <button 
                              type="button" 
                              className={style.close} 
                              onClick={() => handleDeletePhoto(item)}
                              aria-label="delete photo"
                            >
                              <svg
                                width="16"
                                height="16"
                                className={style.icon}>
                                <use href={sprite + "#cross"}></use>
                              </svg>
                              {/* close */}
                            </button> 
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  <div className="inputWrapper">
                    <Field
                      type="file"
                      accept='image/*, .png, .gif, .jpg, .web'
                      multiple 
                      aria-label="select photos from your device"
                      onChange={onChangeFile}
                      name="files"
                      id="files"
                      className={style.inputFiles}
                    />
                    <label
                      id="labelFiles"
                      className={style.labelFiles}
                      htmlFor="files"
                    >
                      Bибрати файли
                    </label>
                  </div>
                  {/* <div className={style.inputWrapper}> */}
                  <div className="inputWrapper">
                    <ErrorMessage
                      name="rate"
                      component="p"
                      className={style.error}
                    />
                    <Rating 
                    transition={true} 
                    size={24}
                    aria-label="rating"
                    fillColor='#FEAE00'
                    onClick={(rate) => {
                      setFieldValue("rate", rate)
                    }} 
                    />
                  </div>
                </div>
                <div className={style.btnWrap}>
                  <button 
                    className={style.btn} 
                    type="submit" 
                    aria-label="submit opinion form"
                  >
                    Зберегти
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik<Values>
        initialValues={{
          category: OpinionsCategory.HOTELS,
          country: "",
          hotel: "",
          date: "",
          opinion: "",
          rate: "0",
          photos: [],
          userNameFromInput: '',
        }}
        onSubmit={(values, { resetForm }: FormikHelpers<Values>) => {
          const { date, country, hotel, rate, opinion, category, userNameFromInput } = values;
          const formWithData = new FormData();
          
          if (n) {
            for (let i=0; i<n; i++){
              formWithData.append("photos", photosFromForm[i]);
            }
          }
          formWithData.append("dateOfVisit", date.toString());
          formWithData.append("country", country);
          formWithData.append("hotel", hotel);
          formWithData.append("rate", rate.toString());
          formWithData.append("category", category);
          formWithData.append("opinion", opinion);
          formWithData.append("userId", userId);
          formWithData.append("userName", userNameFromInput);

          dispatch(addOpinion(formWithData));
    
          resetForm();
          onClose();
        }}
          validationSchema={validationSchemaHotel}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={false}
        >
          {({ errors, touched, handleSubmit, values, handleChange, setFieldValue }) => {

            return (
              <Form className={style.form} autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className={style.inputField}>
                  <DatePicker
                        name="date"
                        value={values.date}
                        calendarAriaLabel="Toggle calendar"
                        format="y-MM"
                        locale="uk-UK"
                        required
                        maxDetail="year"
                        onChange={(date) => {
                          setFieldValue("date", date)
                        }}
                  />
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="userNameFromInput"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      className={`${style.input} ${
                        errors.userNameFromInput && touched.userNameFromInput ? style.inputError : ""}`}
                      type="text"
                      placeholder="Ваше імʼя"
                      name="userNameFromInput"
                      id="userNameFromInput"
                      aria-label="name"
                      value={values.userNameFromInput}
                      onChange={handleChange}
                    />
                  </div>            
                  <div className={style.inputWrapper}>
                    <label
                      id="labelCountry"
                      className={style.labelCountry}
                      htmlFor="country"
                    >
                      Оберіть країну
                    </label>
                    <ErrorMessage
                      name="country"
                      component="p"
                      className={style.error}
                    />
                    < Field
                      as="select" 
                      name="country"
                      value={values.country}
                      aria-label="select country"
                      onChange={handleChange}
                      id="country"
                      className={style.country}
                    >
                      <option value="default"></option>
                      {countries.map(item => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                    </Field>
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="hotel"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      className={`${style.input} ${
                        errors.opinion && touched.opinion ? style.inputError : ""}`}
                      type="text"
                      placeholder="Назва готелю"
                      name="hotel"
                      aria-label="hotel name"
                      id="hotel"
                      value={values.hotel}
                      onChange={handleChange}
                    />
                  </div>            
                  <div className="inputWrapper">
                    <ErrorMessage
                      name="opinion"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      as="textarea"
                      className={`${style.input} ${
                        errors.opinion && touched.opinion ? style.inputError : ""
                      } ${style.inputQuestion}`}
                      placeholder="Відгук про відпочинок"
                      name="opinion"
                      id="opinion"
                      aria-label="opinion"
                      value={values.opinion}
                      onChange={handleChange}
                    />
                  </div>
                  {photosUrlFromForm && (
                    <ul className={style.photoList}>
                      {photosUrlFromForm.map((item) => {
                        return (
                          <li className={style.photoWrap} key={item.fileBlobUrl}>
                            <img className={style.photo} src={item.fileBlobUrl} alt="vacation"/>
                            <button 
                              type="button" 
                              aria-label="delete photo" 
                              className={style.close} 
                              onClick={() => handleDeletePhoto(item)}
                            >
                              <svg
                                width="16"
                                height="16"
                                className={style.icon}>
                                <use href={sprite + "#cross"}></use>
                              </svg>
                            </button> 
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  <div className="inputWrapper">
                    <Field
                      type="file"
                      placeholder="add photo"
                      accept='image/*, .png, .gif, .jpg, .web'
                      multiple 
                      aria-label="select photos from your device"
                      onChange={onChangeFile}
                      name="files"
                      id="files"
                      className={style.inputFiles}
                    />
                    <label
                      id="labelFiles"
                      className={style.labelFiles}
                      htmlFor="files"
                    >
                      Bибрати файли
                    </label>
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="rate"
                      component="p"
                      className={style.error}
                    />
                    <Rating 
                    transition={true} 
                    size={24}
                    aria-label="rating"
                    fillColor='#FEAE00'
                    onClick={(rate) => {
                      setFieldValue("rate", rate)
                    }} 
                    />
                  </div>
                </div>
                <div className={style.btnWrap}>
                  <button 
                    className={style.btn} 
                    type="submit" 
                    aria-label="submit opinion form"
                  >
                    Зберегти
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default OpinionForm;