import { PhotoObject } from "../interfaces/interfaces";


export default function countFiles(files: PhotoObject[]): void {
  const label = document.getElementById('labelFiles') as HTMLLabelElement;
  const labelName = "Bибрати файли";
  let fileName = labelName;

  if (files.length === 1) {
    // fileName = files[0].fileName;
    fileName = `${files.length} файл вибрано`
  }
  else if (files.length < 5 && files.length > 0) {
    fileName = `${files.length} файли вибрано`;
  }
  else if (files.length >= 5) {
    fileName = `${files.length} файлів вибрано`;
  }
  label.innerHTML = fileName;
}