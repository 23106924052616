import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { RootState } from "../store";
import { DeleteType, OpinionsCategory } from "../../interfaces/interfaces";

// axios.defaults.baseURL = 'http://localhost:3000';

axios.defaults.baseURL ='https://hilarious-meta-tourdream.koyeb.app/';

const setAuthHeader = (token: string) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};


export const getOpinionsByCategory = createAsyncThunk(
    'opinions/getOpinionsByCategory',
    async (category: OpinionsCategory , thunkAPI) => {
        try {
            const res = await axios.get(`/opinions/${category}`);
            return res.data;        
        } catch (error: any) {
            toast.error(error.response.data.message,
                { theme: 'colored' }
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

export const getOwnOpinionsByCategory = createAsyncThunk(
    'opinions/getOwnOpinionsByCategory',
    async (category: OpinionsCategory, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const persistedToken = state.auth.token;

        if (persistedToken === null) {
             return thunkAPI.rejectWithValue('Unable to fetch user');
        }
        try {
            setAuthHeader(persistedToken);
            const res = await axios.get(`opinions/own/${category}`);
            return res.data;
        } catch (error: any) {
            toast.error(error.response.data.message,
                { theme: 'colored' }
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

export const addOpinion = createAsyncThunk(
    'opinions/addOpinions',
    async (credentials: FormData, thunkAPI) => {
        try {
            const res = await axios.post('/opinions', credentials);
            toast.success('Ваш вігук успішно відправлено',
                { theme: 'colored' }
            );
            return res.data; 
        } catch (error: any) {
            toast.error(error.response.data.message,
                { theme: 'colored' }
            )
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

export const deleteOpinion = createAsyncThunk(
    'news/deleteOpinion', 
    async (dataForDelete: DeleteType, thunkAPI) => {
        const { id, filenames } = dataForDelete;
        const state = thunkAPI.getState() as RootState;
        const persistedToken = state.auth.token;

        if (persistedToken === null) {
            return thunkAPI.rejectWithValue('Unable to fetch user');
        }
        try {
            setAuthHeader(persistedToken);
            await axios.delete(`/opinions/${id}`, { data: filenames });
            return id;
        } catch (error: any) {
            console.log(error.message)
        }
    }
)