import { PhotoObject } from "../interfaces/interfaces";

export default function changePhotos(files: FileList, n: number) {
    const fileArray: File[] = [];
    const fileUrlArray: PhotoObject[] =[];

    for (let i=0; i<n ; i++) {
        fileArray.push(files[i]);
        fileUrlArray.push({
            fileName: files[i].name,
            fileBlobUrl: URL.createObjectURL(files[i]),
        });
    } 

    return {
        fileArray,
        fileUrlArray,
    }
}