import React from "react";
import { NavLink } from "react-router-dom";

import style from "./NotFoundPage.module.css";
import notFound from "../../images/404.svg";


const NotFoundPage: React.FC = () => {
  return (
    <div className={style.notFound_thumb}>
      <img
        loading="lazy"
        className={style.img}
        src={notFound}
        alt="Сторінку не знайдено"
      />
      <h2 className={style.title}>
        Йой! Схоже, сторінка, яку Ви шукаєте, не існує
      </h2>
      <NavLink className={style.homeLink} to="/" aria-label="transfer to home page">
        На головну
      </NavLink>
    </div>
  );
};

export default NotFoundPage;
