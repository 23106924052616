import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";

interface Props {
  component: React.ComponentType
  path?: string
}


export const PrivateRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const { isLoggedIn, token } = useAuth();

  if (isLoggedIn || token !== null) {
    return <RouteComponent />
  }

  return <Navigate to="/login" />
}

