import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../Logo";
import Nav from "../Nav";
import BurgerMenu from "../BurgerMenu";
import AuthContact from "../AuthContact";
// import ContainerWrap from "../ContainerWrap";
import style from "./Header.module.css";


const Header: React.FC = () => {
  const location = useLocation();
  return (
    <header className={
      location.pathname !== "/"
            ? style.header_blue
            : ''}>
        <div className={style.wrap}>
          <div className={style.logo}>
            <Logo />
          </div>
          <div className={style.navWrap}>
          
          <BurgerMenu />
          <Nav />
          <AuthContact/>
       
          </div>
        </div>
  </header>
  );
};

export default Header;
