import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { TourFeedbackFormValues } from "../../components/TourFeedbackForm/interfaces";
import { RootState } from "redux/store";

// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL ='https://hilarious-meta-tourdream.koyeb.app/';
const setAuthHeader = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getRequests = createAsyncThunk(
    'requests/getRequests',
    async (_, thunkAPI) => {
    try {
        const res = await axios.get('/requests');
        console.log("res.data", res.data);
        return res.data;        
    } catch (error: any) {
        toast.error(error.response.data.message,
        { theme: 'colored' }
        );
        return thunkAPI.rejectWithValue(error.message);        
    }
})

export const addRequest = createAsyncThunk(
    'requests/addRequests',
    async (credentials: TourFeedbackFormValues, thunkAPI) => {
        try {
            const res = await axios.post('/requests', credentials);
            toast.success('Ваш запит успішно відправлено',
                { theme: 'colored' }
            );
            return res.data;            
        } catch (error: any) {
            toast.error(error.response.data.message,
                { theme: 'colored' }
            )
            return thunkAPI.rejectWithValue(error.message);
        }
    }
)

export const getOwnRequests = createAsyncThunk(
    'requests/getOwnRequests',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const persistedToken = state.auth.token;

        if (persistedToken === null) {
             return thunkAPI.rejectWithValue('Unable to fetch user');
        }
        try {
        setAuthHeader(persistedToken);
        const res = await axios.get('/requests/own');
        return res.data;        
    } catch (error: any) {
        toast.error(error.response.data.message,
        { theme: 'colored' }
        );
        return thunkAPI.rejectWithValue(error.message);        
    }
})
