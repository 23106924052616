import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";

import {
  selectIsLoggedIn,
  selectUserId,
  selectUserName,
} from "../../redux/auth/selectors";
import { useAppDispatch } from "../../redux/hooks";
import { addOpinion } from "../../redux/opinions/operations";
import countFiles from "../../utils/countFiles";
import deletePhotos from "../../utils/deletePhotos";
import changePhotos from "../../utils/changePhotos";
import { ModalProps, PhotoObject, OpinionsCategory } from "../../interfaces/interfaces";
import { Values, ValuesSimple} from './interfaces';
import { validationSchema, validationSchemaSimple } from "../../schemas/opinions";

import "../../index.css";
import style from "./OpinionFormAgency.module.css";
import sprite from "../../images/sprite.svg";


const OpinionForm: React.FC<ModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);
  const userName = useSelector(selectUserName);
  const isLoggedIn: boolean = useSelector(selectIsLoggedIn);

  const [photosFromForm, setPhotosFromForm] = useState<File[]>([]);
  const [photosUrlFromForm, setPhotosUrlFromForm] = useState<PhotoObject[]>([]);
  
  const n: number = photosFromForm.length;

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = e.target.files?.length;
    const files = e.target.files;

    if (n && files) {
      const { fileArray, fileUrlArray } = changePhotos(files, n);

      setPhotosFromForm([
        ...photosFromForm, 
        ...fileArray
      ]);
      setPhotosUrlFromForm([
        ...photosUrlFromForm, 
        ...fileUrlArray
      ]);
    }
  };

  const handleDeletePhoto = (item: PhotoObject) => {
    const { urlArrayAfterDeleting, fileArrayAfterDeleting } = deletePhotos(item, photosFromForm, photosUrlFromForm);

    setPhotosUrlFromForm([
      ...urlArrayAfterDeleting
    ]);
    setPhotosFromForm([
      ...fileArrayAfterDeleting
    ]);
  };
  // countFiles(photosFromForm , n);
  useEffect(() => {
    countFiles(photosUrlFromForm);
    console.log("photosUrlFromForm", photosUrlFromForm);
  }, [photosUrlFromForm])

  return (
    <div className={style.formWrap}>
      {isLoggedIn && userName ? (
        <Formik<ValuesSimple>
          initialValues={{
            category: OpinionsCategory.AGENCY,
            opinion: "",
            rate: "0",
            photos: [],
          }}
          onSubmit={ async (values, { resetForm }: FormikHelpers<ValuesSimple>) => {
            const { rate, opinion, category } = values;
            const formWithData = new FormData();

            if (n) {
              for (let i = 0; i < n; i++) {
                formWithData.append("photos", photosFromForm[i]);
              }
            }

            formWithData.append("rate", rate.toString());
            formWithData.append("category", category);
            formWithData.append("opinion", opinion);
            formWithData.append("userId", userId);
            formWithData.append("userName", userName);

            await dispatch(addOpinion(formWithData));

            resetForm();
            onClose();
          }}
          validationSchema={validationSchemaSimple}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={false}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form
                className={style.form}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className={style.inputField}>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="opinion"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      as="textarea"
                      className={`${style.input} ${
                        errors.opinion && touched.opinion
                          ? style.inputError
                          : ""
                      } ${style.inputQuestion}`}
                      placeholder="Відгук про агенцію"
                      name="opinion"
                      id="opinion"
                      value={values.opinion}
                      onChange={handleChange}
                      aria-label="opinion"
                    />
                  </div>
                  {photosUrlFromForm && (
                    <ul className={style.photoList}>
                      {photosUrlFromForm.map((item) => {
                        return (
                          <li
                            className={style.photoWrap}
                            key={item.fileBlobUrl}
                          >
                            <img
                              className={style.photo}
                              src={item.fileBlobUrl}
                              alt="vacation"
                            />
                            <button
                              type="button"
                              className={style.close}
                              onClick={() => handleDeletePhoto(item)}
                              aria-label="delete photo"
                            >
                              {/* close */}
                              <svg
                                width="16"
                                height="16"
                                className={style.icon}
                              >
                                <use href={sprite + "#cross"}></use>
                              </svg>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  <div className={style.inputWrapper}>
                    <Field
                      type="file"
                      placeholder="add photo"
                      accept="image/*, .png, .gif, .jpg, .web"
                      multiple
                      onChange={onChangeFile}
                      name="files"
                      id="files"
                      className={style.inputFiles}
                      aria-label="choose ptotos from your device"
                    />
                    <label
                      id="labelFiles"
                      className={style.labelFiles}
                      htmlFor="files"
                    >
                      Bибрати файли
                    </label>
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                        name="rate"
                        component="p"
                        className={style.error}
                    />
                    <Rating
                      transition={true}
                      size={24}
                      fillColor="#FEAE00"
                      aria-label="rating"
                      onClick={(rate) => {
                        setFieldValue("rate", rate);
                      }}
                    />
                  </div>
                </div>
                <div className={style.btnWrap}>
                  <button 
                    className={style.btn} 
                    type="submit"
                    aria-label="submit opinion form"
                  >
                    Зберегти
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik<Values>
          initialValues={{
            category: OpinionsCategory.AGENCY,
            opinion: "",
            rate: "0",
            photos: [],
            userNameFromInput: "",
          }}
          onSubmit={(values, { resetForm }: FormikHelpers<Values>) => {
            const { rate, opinion, category, userNameFromInput } = values;
            const formWithData = new FormData();

            if (n) {
              for (let i = 0; i < n; i++) {
                formWithData.append("photos", photosFromForm[i]);
              }
            }

            formWithData.append("rate", rate.toString());
            formWithData.append("category", category);
            formWithData.append("opinion", opinion);
            formWithData.append("userId", userId);
            formWithData.append("userName", userNameFromInput);

            dispatch(addOpinion(formWithData));

            resetForm();
            onClose();
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={false}
        >
          {({
            errors,
            touched,
            handleSubmit,
            values,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form
                className={style.form}
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className={style.inputField}>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="userNameFromInput"
                      component="p"
                      className={style.error}
                    />
                    <Field
                      className={`${style.input} ${
                        errors.userNameFromInput && touched.userNameFromInput
                          ? style.inputError
                          : ""
                      }`}
                      type="text"
                      placeholder="Ваше імʼя"
                      name="userNameFromInput"
                      id="userNameFromInput"
                      value={values.userNameFromInput}
                      onChange={handleChange}
                      aria-label="name"
                    />
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="opinion"
                      component="p"
                      className="error"
                    />
                    <Field
                      as="textarea"
                      className={`${style.input} ${
                        errors.opinion && touched.opinion
                          ? style.inputError
                          : ""
                      } ${style.inputQuestion}`}
                      placeholder="Відгук про агенцію"
                      name="opinion"
                      id="opinion"
                      value={values.opinion}
                      onChange={handleChange}
                      aria-label="opinion"
                    />
                  </div>
                  {photosUrlFromForm && (
                    <ul className={style.photoList}>
                      {photosUrlFromForm.map((item) => {
                        return (
                          <li
                            className={style.photoWrap}
                            key={item.fileBlobUrl}
                          >
                            <img
                              className={style.photo}
                              src={item.fileBlobUrl}
                              alt="vacation"
                            />
                            <button
                              type="button"
                              className={style.close}
                              onClick={() => handleDeletePhoto(item)}
                              aria-label="delete photo"
                            >
                              <svg
                                width="16"
                                height="16"
                                className={style.icon}>
                                <use href={sprite + "#cross"}></use>
                              </svg>
                              {/* close */}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className={style.inputWrapper}>
                    <Field
                      type="file"
                      placeholder="add photo"
                      accept="image/*, .png, .gif, .jpg, .web"
                      multiple
                      onChange={onChangeFile}
                      name="files"
                      id="files"
                      className={style.inputFiles}
                      aria-label="choose photos from your device"
                    />
                    <label
                      id="labelFiles"
                      className={style.labelFiles}
                      htmlFor="files"
                    >
                      Bибрати файли
                    </label>
                  </div>
                  <div className={style.inputWrapper}>
                    <ErrorMessage
                      name="rate"
                      component="p"
                      className={style.error}
                    />
                    <Rating
                      transition={true}
                      size={24}
                      fillColor="#FEAE00"
                      aria-label="rating"
                      onClick={(rate) => {
                        setFieldValue("rate", rate);
                      }}
                    />
                  </div>
                </div>
                <div className={style.btnWrap}>
                  <button 
                    className={style.btn} 
                    type="submit"
                    aria-label="submit opinion form"
                  >
                    Зберегти
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default OpinionForm;
