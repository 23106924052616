import React, {useState} from "react";

import ContainerWrap from "../ContainerWrap";
import RequestListItem from "../RequestListItem";
import Title from "../Title";
import Button from "../Button";
import { RequestType } from "./interfaces";
import { RequestsListProps } from "../../interfaces/interfaces";

import style from "./RequestsList.module.css";


const RequestsList: React.FC<RequestsListProps<RequestType>> = ({ requests }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [displayCount, setDisplayCount] = useState<number>(5);

  const filteredRequests = requests.filter(
    (request) =>
      request.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.phone?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    setDisplayCount(filteredRequests.length);
  };

  return (
    <ContainerWrap>
      <Title text={'Список запитів на підбір туру'} />
      <div className={style.inputWrapper}>
      <label htmlFor="query">Пошук запиту за ім'ям, email, телефоном:</label>
      <input
        type="text"
        id="query"
        placeholder="Почніть вводити текст"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={style.input}
        aria-label="search request"
      />
      </div>

      <ul className={style.list}>
        {filteredRequests.slice(0, displayCount).map((request) => (
          <RequestListItem key={request._id} request={request}/>
        ))}
      </ul>
      {displayCount < filteredRequests.length && (
        <Button 
          text="Завантажити ще" 
          aria-label="Load more requests"
          onClick={handleLoadMore}
        />
      )}
    </ContainerWrap>
  );
};

export default RequestsList;