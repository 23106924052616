import { RootState } from "../store";

export const selectIsRegistered = (state: RootState) => state.auth.isRegistered;
export const selectIsRefreshing = (state: RootState) => state.auth.isRefreshing;
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUserId = (state: RootState) => state.auth.user.id;
export const selectUserName = (state: RootState) => state.auth.user.name;
export const selectAllUsers = (state: RootState) => state.auth.users;

