import React, {useState} from "react";

import OpinionFormHotel from "../OpinionFormHotel";
import OpinionFormAgency from "../OpinionFormAgency";
import { ModalProps } from "../../interfaces/interfaces";

import style from "./OpinionModalToggle.module.css";


const OpinionMadalToggle: React.FC<ModalProps> = ({onClose}) => {
    const [hotelModal, setHotelModal] = useState<boolean>(true);

    return (
        <div className={style.wrap}>
            <div className={style.btnWrap}>
                <button 
                    className={`${style.btn} ${hotelModal ? style.btnActiv : " "}`} 
                    type="button" 
                    onClick={()=> {setHotelModal(true)}}
                    aria-label="rate tour"
                >
                    Оцініть відпочинок
                </button>
                <button 
                    className={`${style.btn} ${!hotelModal ? style.btnActiv : " "}`} 
                    type="button" 
                    onClick={()=> {setHotelModal(false)}}
                    aria-label="rate agency"
                >
                    Оцініть агенство
                </button>
            </div>
            <div className={style.formWrap}>
                {hotelModal ? (<OpinionFormHotel onClose={onClose}/>) : (<OpinionFormAgency onClose={onClose}/>)}
            </div>
        </div>
    );
};

export default OpinionMadalToggle;