import { createSlice, PayloadAction  } from "@reduxjs/toolkit";

import {
  registerUser,
  logIn,
  logOut,
  current,
  updateUser,
  getAllUsers
} from "./operations";
import { User } from "../../interfaces/interfaces";

interface AuthState {
  user: User;
  token: string | null;
  isLoggedIn: boolean;
  isRefreshing: boolean;
  isLoading: boolean;
  isRegistered: boolean;
  error: any;
  users: User[];
}

const initialState: AuthState = {
  user: { id: null, name: null, email: null, phone: null, isAdmin: false },
  token: null,
  isLoggedIn: false,
  isRefreshing: false,
  isLoading: false,
  isRegistered: false,
  error: null,
  users: [],
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeIsRegistered(state, action: PayloadAction<boolean>) {
      state.isRegistered = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(registerUser.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
          isAdmin: action.payload.isAdmin,
        };
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(logIn.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
          isAdmin: action.payload.isAdmin,
        }
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.error = action.payload;
        console.log( action.payload);
        
      })
      .addCase(logOut.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logOut.fulfilled, state => {
        state.user = { id: null, name: null, email: null, phone: null, isAdmin: false };
        state.token = null;
        state.isLoggedIn = false;
        state.isLoading = false;
      })
      .addCase(logOut.rejected, (state, action) => {
        state.isRefreshing = false;
        state.error = action.payload;
      })
      .addCase(current.pending, state => {
        state.isLoading = true;
        state.isRefreshing = true;
        state.error = null;
      })
      .addCase(current.fulfilled, (state, action) => {
        state.user = {
          id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
          isAdmin: action.payload.isAdmin,
        }
        state.isLoggedIn = true;
        state.isLoading = false;
        state.isRefreshing = false;
      })
      .addCase(current.rejected, (state, action) => {
        state.user = { id: null, name: null, email: null, phone: null, isAdmin: false };
        state.token = null;
        state.isRefreshing = false;
        state.isLoggedIn = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, state => {
        state.isLoading = true;
        state.isRefreshing = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = {
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.phone,
          isAdmin: action.payload.isAdmin,
        };
        state.isLoggedIn = true;
        state.isLoading = false;
        state.isRefreshing = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isRefreshing = false;
        state.error = action.payload;
      })
      .addCase(getAllUsers.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const authReducer = authSlice.reducer;

export const { changeIsRegistered } = authSlice.actions;
