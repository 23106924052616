import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { authReducer } from './auth/authSlice';
import { newsReducer } from './news/newsSlice';
import { requestsReducer } from './requests/requestsSlice';
import { opinionsReducer } from './opinions/opinionsSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  // whitelist: ['user', 'token'],
};

const newsPersistConfig = {
  key: 'news',
  storage,
};

const requestsPersistConfig = {
  key: 'requests',
  storage,
}

const opinionsRersistConfig = {
  key: 'opinions',
  storage,
}

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    news: persistReducer(newsPersistConfig, newsReducer),
    requests: persistReducer(requestsPersistConfig, requestsReducer),
    opinions: persistReducer(opinionsRersistConfig, opinionsReducer)
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
