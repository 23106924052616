import React from "react";

import style from "./StarComponent.module.css";
import sprite from "../../images/sprite.svg";

type Props = {
    icon: string;
}


const StarComponent: React.FC<Props> = ({icon}: Props) => {

    return (
        <div className={style.star} aria-label="rating">
            <svg className={style.starIcon} width="24" height="24">
                <use href={sprite + icon}></use>
            </svg> 
        </div>
    );
};

export default StarComponent;