import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { RootState } from "../store";
import { DeleteType } from "../../interfaces/interfaces";

// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL ='https://hilarious-meta-tourdream.koyeb.app/';

const setAuthHeader = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};


export const getNews = createAsyncThunk('news/getNews', async (_, thunkAPI) => {
  try {
    const res = await axios.get('/news');
    return res.data;
  } catch (error: any) {
    toast(error.response.data.message);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const addNews = createAsyncThunk(
  'news/addNews',
  async (credentials: FormData, thunkAPI) => {

  const state = thunkAPI.getState() as RootState;
  const persistedToken = state.auth.token;

  if (persistedToken === null) {
    return thunkAPI.rejectWithValue('Unable to fetch user');
  }

  try {
    setAuthHeader(persistedToken);
    const res = await axios.post('/news', credentials);
    console.log('res', res)
    toast.success('Новина успішно додана',
                { theme: 'colored' }
            );
    return res.data;
  } catch (error: any) {
     toast.error(error.response.data.message,
                { theme: 'colored' }
            )
    return thunkAPI.rejectWithValue(error.message);
  }
  }
);

export const updateNews = createAsyncThunk(
  'news/updateNews',
  async ({ id, formWithData }: { id: string; formWithData: FormData }, thunkAPI) => {

  const state = thunkAPI.getState() as RootState;
  const persistedToken = state.auth.token;

  if (persistedToken === null) {
    return thunkAPI.rejectWithValue('Unable to fetch user');
  }

    try {
      setAuthHeader(persistedToken);
      const res = await axios.patch(`/news/${id}`, formWithData);
      toast.success('Новина успішно оновлена',
                { theme: 'colored' }
            );
      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteNews = createAsyncThunk(
  'news/deleteNews', 
  async (dataForDelete: DeleteType, thunkAPI) => {
    const { id, filenames } = dataForDelete;
    const state = thunkAPI.getState() as RootState;
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue('Unable to fetch user');
    }
    try {
      setAuthHeader(persistedToken);
      await axios.delete(`/news/${id}`, { data: filenames });
      toast.success('Новина успішно вилучена',
                { theme: 'colored' }
            );
      return id;
    } catch (error: any) {
      console.log(error.message)
    }
  }
);

export const deleteNewsPhotos = createAsyncThunk(
  'news/deleteNewsPhotos', 
  async (dataForDelete: DeleteType, thunkAPI) => {
    const { id, filenames } = dataForDelete;
    const state = thunkAPI.getState() as RootState;
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      return thunkAPI.rejectWithValue('Unable to fetch user');
    }
    try {
      setAuthHeader(persistedToken);
      await axios.delete(`/news/photos/${id}`, { data: filenames });
      console.log('delete', id);
      return id;
    } catch (error: any) {
      console.log(error.message)
    }
  }
);
