import React from "react";
import { NavLink } from "react-router-dom";

import ContainerWrap from "../ContainerWrap";
import ContactData from "../ContactData";

import style from "./Footer.module.css";
import logo from "../../images/logo.svg";


const Footer: React.FC = () => {
  return (
    <footer className={style.footer}>
      <ContainerWrap>
        <ContactData />
        <NavLink to="/">
          <img className={style.logo} src={logo} alt="logo" />
        </NavLink>
        <p className={style.copyright}>Copyright © 2013-2016 "Подорож у мрії"</p>
      </ContainerWrap>
    </footer>
  );
};

export default Footer;
