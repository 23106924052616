import { createSlice } from "@reduxjs/toolkit";
import { addRequest, getRequests, getOwnRequests } from "./operations";
import { TourFeedbackFormValues } from "../../components/TourFeedbackForm/interfaces";

interface RequestsValues {
    requests: TourFeedbackFormValues[];
    ownRequests: TourFeedbackFormValues[];
    isLoading: boolean,
    error: any
}

const initialState: RequestsValues = {
    requests: [],
    ownRequests: [],
    isLoading: false,
    error: null,
};

const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addCase(getRequests.pending, state => {
                state.isLoading = true;
            })
            .addCase(getRequests.fulfilled, (state, action) => {
                console.log("payload", action.payload);
                state.requests = [...action.payload];
                state.isLoading = false;
                console.log("in state", state.requests);
            })
            .addCase(getRequests.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addRequest.pending, state => {
                state.isLoading = true;
            })
            .addCase(addRequest.fulfilled, (state, action) => {
                state.requests = [action.payload, ...state.requests];
                state.isLoading = false; 
            })
            .addCase(addRequest.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getOwnRequests.pending, state => {
                state.isLoading = true;
            })
            .addCase(getOwnRequests.fulfilled, (state, action) => {            
                state.ownRequests = [...action.payload];
                state.isLoading = false;
            })
            .addCase(getOwnRequests.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
});

export const requestsReducer = requestsSlice.reducer;