import React, { MouseEvent, useEffect} from "react";
import { createPortal } from "react-dom";

import style from "./Modal.module.css";
import sprite from "../../images/sprite.svg";
// import zIndex from '@mui/material/styles/zIndex';
// import { useSpring } from 'react-spring';

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const modalRoot = document.querySelector("#modal-root") as HTMLElement;

const Modal: React.FC<ModalProps> = ({children, isOpen, onClose}) => {

  const page = document.getElementsByTagName('html')[0] as HTMLElement;
    
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      page.style.overflow = 'hidden';
    }
  
    return () => {
      document.body.style.overflow = 'unset';
      page.style.overflow = 'unset';
    }
        
  }, [isOpen]);

  useEffect(() => {
    const closeESC = (e: KeyboardEvent): void => {
      if (e.code === "Escape") {
        onClose();
      };
    };

    window.addEventListener("keydown", closeESC);

    return () => {
      window.removeEventListener("keydown", closeESC);
    }

  }, [onClose]);
  
  // animation
  // type ModalTransition = {
  //   state: boolean;
  //   options: string;
  // }

  // const maodalTransition = useTransition(isOpen, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 1 },
  //   config: {
  //     duration: 500
  //   },
  // });

  // const spring = useSpring({
  //   opacity: isOpen ? 1 : 0,
  //   transform: "translate(-100%, -100%)",
  //   config: {
  //     duration: 500,
  //   },
  // });


  const handleBackDropClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  }
  
  return (
    isOpen ? ( 
    createPortal(
      <div className={style.overlay} onClick={handleBackDropClick} >
        <div>
          <div className={style.modalWrapper} >
            <button aria-label="Close modal window" className={style.crossBtn} type='button' onClick={onClose}>
              <svg className={style.exit} width='24' height='24'>
                  <use className={style.cross} href={sprite + '#cross'}></use>
              </svg>
            </button>
            <div className={style.childrenWrap}>
              {children}
            </div>
            
            
          </div>
        </div>
        </div>,  modalRoot
      )) 
      : null
  );
};

export default Modal;