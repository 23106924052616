import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../redux/hooks";
import { selectAllUsers} from "../../redux/auth/selectors";
import {getAllUsers} from "../../redux/auth/operations";
import {getRequests} from "../../redux/requests/operations";
import {selectRequests} from "../../redux/requests/selectors";
import AdminForm from "../../components/AdminForm/AdminForm";
import RequestsList from "../../components/RequestsList";
import UsersList from "../../components/UsersList/UsersList";
import ButtonUp from "components/ButtonUp";


const AdminPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectAllUsers);

  

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getRequests());
  }, [dispatch]);

  const requests = useSelector(selectRequests);
  console.log(requests); 

  return (
    <>
      <AdminForm />
      <UsersList users={users}/>
      <RequestsList requests={requests} />
      <ButtonUp />
    </>
  );
};

export default AdminPage;