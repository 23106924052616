import { createSlice } from "@reduxjs/toolkit";

import { getNews, addNews, updateNews, deleteNews, deleteNewsPhotos } from "./operations";
import { OneNewsType } from "../../components/NewsItem/interfaces";

interface AdminFormValues {
  news: Array<OneNewsType>,
  isLoading: boolean,
  isDeleting: boolean, 
  error: any
}

const initialState: AdminFormValues = {
  news: [],
  isLoading: false,
  isDeleting: false,
  error: null,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {

  },
  extraReducers: builder =>
    builder
      .addCase(getNews.pending, state => {
        state.isLoading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.news = action.payload;
        state.isLoading = false;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addNews.pending, state => {
        state.isLoading = true;
      })
      .addCase(addNews.fulfilled, (state, action) => {
        state.news = [action.payload, ...state.news];
        state.isLoading = false;
      })
      .addCase(addNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateNews.pending, state => {
        state.isLoading = true;
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.news = state.news.map((news) =>
          news._id === action.payload._id ? { ...news, ...action.payload } : news
        );
      })
      .addCase(updateNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteNews.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.news = state.news.filter((news) => news._id !== action.payload);
      })
      .addCase(deleteNews.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteNewsPhotos.pending, state => {
        state.isLoading = true;
      })
      .addCase(deleteNewsPhotos.fulfilled, (state, action) => {
        // console.log('action.payload ', action.payload);
      
        state.news = state.news.map((item) => {
          if (item._id === action.payload) {
            const updNews = { ...item, photos: [] };
            console.log('updNews', updNews);
            return updNews;
          }
          return item;
        });
      
        console.log('state.news', state.news);
      })
      .addCase(deleteNewsPhotos.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }),
});

export const newsReducer = newsSlice.reducer;
