import { createSlice } from "@reduxjs/toolkit";

import { getOpinionsByCategory, addOpinion, getOwnOpinionsByCategory, deleteOpinion } from "./operations";
import { OpinionType } from "../../components/OpinionList/interfaces";


interface OpinionsValues {
    opinions: OpinionType[];
    ownOpinions: OpinionType[];
    isLoading: boolean;
    error: any;
}

const initialState: OpinionsValues = {
    opinions: [],
    ownOpinions:[],
    isLoading: false,
    error: null,
}

const opinionsSlice = createSlice({
    name: 'opinions',
    initialState,
    reducers: {},
    extraReducers: builder => 
        builder
            .addCase(getOpinionsByCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(getOpinionsByCategory.fulfilled, (state, action) => {
                state.opinions = action.payload;
                state.isLoading = false;
            })
            .addCase(getOpinionsByCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addOpinion.pending, state => {
                state.isLoading = true;
            })
            .addCase(addOpinion.fulfilled, (state, action) => {
                state.opinions = [action.payload, ...state.opinions];
                state.isLoading = false;
            })
            .addCase(addOpinion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getOwnOpinionsByCategory.pending, state => {
                state.isLoading = true;
            })
            .addCase(getOwnOpinionsByCategory.fulfilled, (state, action) => {
                state.ownOpinions = action.payload;
                state.isLoading = false;
            })
            .addCase(getOwnOpinionsByCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteOpinion.pending, state => {
                state.isLoading = true;
            })
            .addCase(deleteOpinion.fulfilled, (state, action) => {
                state.opinions = state.opinions.filter((opinions) => opinions._id !== action.payload);
            })
            .addCase(deleteOpinion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            }),
})

export const opinionsReducer = opinionsSlice.reducer;