import React, { useState } from "react";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";

import { updateUser } from "../../redux/auth/operations";
import { useAppDispatch } from "../../redux/hooks";
import { UserDataProps, Values } from "./interfaces";
import { validationSchema } from "../../schemas/login";

import style from "./UserData.module.css";
import sprite from "../../images/sprite.svg";
import Title from "components/Title";


const UserData: React.FC<UserDataProps> = ({ user }) => {
  const { name, email, phone } = user;

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const onToggleReadOnly = () => {
    setReadOnly(prevState => !prevState);
  }
  
  const handleSubmit = async (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    console.log('user data', values);
    try {
      await dispatch(updateUser(values));
    } catch (error) {
      console.log(error);  
    }    
    resetForm();
  };

  return (
    <div className={style.formContainer}>
      <Title text={'Мої дані'} />
      <button 
        className={style.editButton} 
        title="Edit" 
        type='button' 
        onClick={onToggleReadOnly}
        aria-label="edit user data"
      >
        <svg className={style.editIcon} width='24' height='24'>
          {readOnly ? <use href={sprite + '#edit'}></use>  
          :  <use className={style.iconCross} href={sprite + '#cross'}></use>}         
        </svg>
      </button>
      <Formik
        initialValues={{
          // userId: id || "",
          name: name || "",
          email: email || "",
          phone: phone || "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnMount={false}
      >
        {({ errors, touched }) => (
          <Form className={style.form} autoComplete="off">
            <div className={style.input_field}>
              <div className={style.input_wrapper}>
                <ErrorMessage name="name" component="p" className={style.error} />
                <Field
                  className={`${style.input} ${
                    errors.name && touched.name ? style.input_error : ""
                  } `}
                  type="text"
                  placeholder="Ім’я"
                  name="name"
                  id="name"
                  readOnly = {readOnly}
                  aria-label="name"
                />
              </div>
              <div className={style.input_wrapper}>
                <ErrorMessage
                  name="email"
                  component="p"
                  className={style.error}
                />
                <Field
                  className={`${style.input} ${
                    errors.email && touched.email ? style.input_error : ""
                  } `}
                  type="email"
                  placeholder="Електронна адреса"
                  name="email"
                  id="email"
                  readOnly = {readOnly}
                  aria-label="email"
                />
              </div>
              <div className={style.input_wrapper}>
                <ErrorMessage
                  name="phone"
                  component="p"
                  className={style.error}
                />
                <Field
                  className={`${style.input} ${
                    errors.phone && touched.phone ? style.input_error : ""
                  } `}
                  type="tel"
                  placeholder="Номер телефону"
                  name="phone"
                  id="phone"
                  readOnly = {readOnly}
                  aria-label="phone"
                />
              </div>
            </div>
            {!readOnly && <button className={style.btn} type="submit" aria-label="submit new user data">
                Зберегти
            </button>}  
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserData;