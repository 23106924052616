import { PhotoObject } from "../interfaces/interfaces";

export default function deletePhotos(item: PhotoObject, photosFromForm: File[], photosUrlFromForm: PhotoObject[]) {
    const urlArrayAfterDeleting = photosUrlFromForm.filter((url) => url.fileBlobUrl !== item.fileBlobUrl);
    const fileArrayAfterDeleting = photosFromForm.filter((file) => file.name !== item.fileName ); 

    return {
        urlArrayAfterDeleting, 
        fileArrayAfterDeleting,
    }
}