import React from "react";

import TourFeedbackForm from "components/TourFeedbackForm";
import finger1 from "../../images/callback/finger1x.png";
import finger2 from "../../images/callback/finger2x.png";

import ContainerWrap from "../ContainerWrap/ContainerWrap";

import style from "./TourRequest.module.css";


const TourRequest: React.FC = () => {
  return (
    <div className={style.bgWrapper}>
      <ContainerWrap>  
        <h2 className={style.title}>
            Заповніть форму для зворотнього зв’язку і ми з радістю надамо Вам
            консультацію
          </h2>
        <div className={style.container} >
         <TourFeedbackForm />
          <img
              loading="lazy"
              className={style.img}
              srcSet={`${finger1} 1x, ${finger2} 2x`}
              src={finger1}
              alt="Дівчина показує форму"
            />
          
          
          
        </div>     
      </ContainerWrap>
    </div>    
  );
};

export default TourRequest;