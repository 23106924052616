import { Outlet, useLocation } from "react-router-dom";
import { Suspense } from "react";

import Spinner from "../Spinner/Spinner";
import Header from "../Header";
import Footer from "../Footer";

import style from "./SharedLayout.module.css";


const SharedLayout = () => {
    const location = useLocation();

    return (
        <div className={
            location.pathname === "/"
                ? [style.container, style.hero_image].join(" ")
                : style.container}>
            <Header />
            <Suspense fallback={<Spinner />}>
            <Outlet/>
            </Suspense>
            <Footer />
        </div>
    )
}

export default SharedLayout;