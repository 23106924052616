import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../redux/hooks";
import { logOut } from "../../redux/auth/operations";
import { Admin, NavDropdownProps } from "../../interfaces/interfaces";
import style from "./NavDropdown.module.css";

const NavDropdown: React.FC<NavDropdownProps> = ({ closeDropdown }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user, isLoggedIn } = useAuth() as { user: Admin; isLoggedIn: boolean };
  const isSmallScreen = window.innerWidth < 768;

  const userLogout = () => {
    dispatch(logOut());
  }

  return (
    <div  className={style.dropdown}>
      <NavLink 
        to="/" 
        end 
        className={
          location.pathname === '/'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="home page"
        onClick={() => closeDropdown()}
      >
        Головна
      </NavLink>
      <NavLink 
        to="/search" 
        className={
          location.pathname === '/search'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="search tour page"
        onClick={() => closeDropdown()}
      >
        Пошук
      </NavLink>
      <NavLink 
        to="/hot-tours" 
        className={
          location.pathname === '/hot-tours'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="hot tours page"
        onClick={() => closeDropdown()}
      >
        Гарячі тури
      </NavLink>
      <NavLink 
        to="/tickets" 
        className={
          location.pathname === '/tickets'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="tickets page"
        onClick={() => closeDropdown()}
      >
        Пошук авіаквитків, готелів
      </NavLink>
      <NavLink 
        to="/news" 
        className={
          location.pathname === '/news'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="news page"
        onClick={() => closeDropdown()}
      >
        Корисна інформація
      </NavLink>
      <NavLink 
        to="/opinions" 
        className={
          location.pathname === '/opinions'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="opinions page"
        onClick={() => closeDropdown()}
      >
        Відгуки
      </NavLink>
      <NavLink 
        to="/about-us" 
        className={
          location.pathname === '/about-us'
            ? [style.link, style.active].join(' ')
            : style.link
        }
        aria-label="about us page"
        onClick={() => closeDropdown()}
      >
      Про нас
      </NavLink>
      {isSmallScreen && 
        (<>
          {user.isAdmin && <NavLink 
            to="/admin" 
            className={
              location.pathname === '/admin'
                ? [style.link, style.active].join(' ')
                : style.link
            }
            aria-label="admin page"
            onClick={() => closeDropdown()}
          >
            Адмін
          </NavLink>}
          {isLoggedIn && <NavLink 
            to="/user" 
            className={
              location.pathname === '/user'
                ? [style.link, style.active].join(' ')
                : style.link
            }
            aria-label="user page"
            onClick={() => closeDropdown()}
          >
            Особистий кабінет
          </NavLink>}
          <a href={`tel:${+380982885050}`} className={style.link} aria-label="phone to us">
            Зв'язатися
          </a>
          {isLoggedIn ?
        <button type='button' className={style.btn} 
        onClick={userLogout}
         aria-label="Log out button" title="Log out">
          Вийти
        </button>      
      :
      <NavLink 
      to="/login" 
      className={
        location.pathname === '/login'
          ? [style.link, style.active].join(' ')
          : style.link}
      aria-label="Log in"
      onClick={() => closeDropdown()}
    >
      Увійти
    </NavLink>
      }  
        </>)
      } 
    </div>
  );
};

export default NavDropdown;


