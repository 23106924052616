import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { store, persistor } from "./redux/store";
import App from "./components/App";

import './index.css';
import './variables.css';

// const clientId = '495505200635-8ugk2s73gd77q7a1qhq5kopp4u5ibmo5.apps.googleusercontent.com';
const clientID = "276823953678-uopvpqfr0b09a8isupp931gp62a5qnkm.apps.googleusercontent.com";
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <Provider store={store}>
  <PersistGate 
  // loading={<Spinner />} 
  persistor={persistor}>
  <BrowserRouter 
  // basename='tourdream-app'
  >
    <GoogleOAuthProvider clientId={clientID}>
    <App />
    </GoogleOAuthProvider>
    </BrowserRouter>
    </PersistGate>
  </Provider>
// </React.StrictMode>
);

