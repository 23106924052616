import React from "react";

import OpinionListItem from "../OpinionListItem";
import {OpinionListProps} from "../../interfaces/interfaces";
import { OpinionType } from "./interfaces";

import style from "./OpinionList.module.css";


const OpinionList: React.FC<OpinionListProps<OpinionType>> = ({opinions, onDelete, isAdmin }) => {

  return (
    <ul className={style.list}>
      {opinions.map((opinion: OpinionType) => {
        return <OpinionListItem key={opinion._id} opinion={opinion} onDelete={onDelete} isAdmin={isAdmin}/>
      })}
    </ul>
  );
};

export default OpinionList ;