import React from "react";
import { NavLink } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../redux/hooks";
import { logOut } from "../../redux/auth/operations";
import { Admin } from "../../interfaces/interfaces";

import style from "./AuthContact.module.css";
import svg from "../../images/sprite.svg";
// import { getRequests } from "../../redux/requests/operations";


const AuthContact: React.FC = () => {
  const { user, isLoggedIn } = useAuth() as { user: Admin; isLoggedIn: boolean };
  const dispatch = useAppDispatch();

  const userLogout = () => {
    dispatch(logOut());
  }
  
  return (
    <div className={style.container}>
      <a href={`tel:${+380982885050}`} className={style.btn} aria-labelledby="contact us" title="Contacts">
        <svg className={style.icon} >
          <use href={svg + '#contacts'}></use>
        </svg>
        </a>
        {isLoggedIn && user.isAdmin && <NavLink to="/admin" className={style.btn} aria-label="Admin page button">
            <svg className={style.icon} >
          <use href={svg + '#key'} ></use>
        </svg>
      </NavLink>}
      {isLoggedIn && <NavLink to="/user" className={style.btn} ria-labelledby="Profile">
            <svg className={style.icon} >
          <use href={svg + '#profile'} width={32} height={32}></use>
        </svg>
      </NavLink>}
      {isLoggedIn ?
        <button type='button' className={style.btn} onClick={userLogout} aria-label="Log out button" title="Log out">
          <svg className={style.icon} >
            <use href={svg + '#logout'}></use>
          </svg>
        </button>      
      :
        <NavLink to='/login' className={style.btn} aria-label="Log in button">
          <svg className={style.icon} >
            <use href={svg + '#login'}></use>
          </svg>
        </NavLink>
      }
    </div>
  );
};

export default AuthContact;