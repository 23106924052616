import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";

import { useAppDispatch } from "../../redux/hooks";
import { addNews } from "../../redux/news/operations";
import changePhotos from "../../utils/changePhotos";
import deletePhotos from "../../utils/deletePhotos";
import Title from "../Title";
import Button from "../Button";
import { AdminFormValues } from "./interfaces";
import { PhotoObject, NewsCategory } from "../../interfaces/interfaces";
import countFiles from "../../utils/countFiles";
import { validationSchemaUpdate } from "../../schemas/news";

import style from "./AdminForm.module.css";
import sprite from "../../images/sprite.svg";


const AdminForm: React.FC = () => {
  const [photosFromForm, setPhotosFromForm] = useState<File[]>([]);
  const [photosUrlFromForm, setPhotosUrlFromForm] = useState<PhotoObject[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const n: number = photosFromForm.length;

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = e.target.files?.length;
    const files = e.target.files;

    if (n && files) {
      const { fileArray, fileUrlArray } = changePhotos(files, n);

      setPhotosFromForm([...photosFromForm, ...fileArray]);
      setPhotosUrlFromForm([...photosUrlFromForm, ...fileUrlArray]);
    }
  };

  const handleDeletePhoto = (item: PhotoObject) => {
    const { urlArrayAfterDeleting, fileArrayAfterDeleting } = deletePhotos(
      item,
      photosFromForm,
      photosUrlFromForm
    );

    setPhotosUrlFromForm([...urlArrayAfterDeleting]);
    setPhotosFromForm([...fileArrayAfterDeleting]);
  };

  const handleSubmit = async (
    values: AdminFormValues,
    { resetForm }: FormikHelpers<AdminFormValues>
  ) => {
    const { title, description, category } = values;
    const formWithData = new FormData();

    if (n) {
      for (let i = 0; i < n; i++) {
        formWithData.append("photos", photosFromForm[i]);
      }
    }
    formWithData.append("title", title);
    formWithData.append("description", description);
    formWithData.append("category", category);

    try {
      await dispatch(addNews(formWithData));
    } catch (error) {
      console.log(error);
    }
    resetForm();
    navigate("/news");
  };

  useEffect(() => {
    countFiles(photosUrlFromForm);
    console.log("photosUrlFromForm", photosUrlFromForm);
  }, [photosUrlFromForm])

  return (
    <Formik<AdminFormValues>
      initialValues={{
        title: "",
        description: "",
        category: NewsCategory.AGENCY,
        photos: [],
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaUpdate}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form className={style.form} autoComplete="off">
          <Title text="Додати новину на сайт" />
          <div className={style.formWrapper}>
            <div>
              <p>Оберіть категорію новини</p>
              <Field
                className={style.select}
                as="select"
                name="category"
                aria-label="select news category"
                value={values.category}
                onChange={handleChange}
              >
                <option value={NewsCategory.AGENCY} aria-label="agency news">
                  {NewsCategory.AGENCY}
                </option>
                <option value={NewsCategory.HOTELS} aria-label="hotels news">
                  {NewsCategory.HOTELS}
                </option>
                <option value={NewsCategory.TOURS} aria-label="tours news">{NewsCategory.TOURS}</option>
              </Field>
            </div>
            <div>
              <ErrorMessage
                className={style.error}
                name="title"
                component="p"
              />
              <Field
                className={`${style.input} ${
                  errors.title && touched.title ? style.input_error : ""
                }`}
                type="text"
                aria-label="news title"
                placeholder="Заголовок новини"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
              />
            </div>
            <div>
              <ErrorMessage
                className={style.error}
                name="description"
                component="p"
              />
              <Field
                className={`${style.textArea} ${
                  errors.description && touched.description
                    ? style.input_error
                    : ""
                }`}
                as="textarea"
                aria-label="news description"
                placeholder="Опис новини"
                name="description"
                id="description"
                value={values.description}
                onChange={handleChange}
              />
            </div>
            {photosUrlFromForm && (
              <ul className={style.photoList}>
                {photosUrlFromForm.map((item) => {
                  return (
                    <li className={style.photoWrap} key={item.fileBlobUrl}>
                      <img
                        className={style.photo}
                        src={item.fileBlobUrl}
                        alt="vacation"
                      />
                      <button
                        type="button"
                        aria-label="delete photo"
                        className={style.close}
                        onClick={() => handleDeletePhoto(item)}
                      >
                        {/* close */}
                        <svg width="16" height="16" className={style.icon}>
                          <use href={sprite + "#cross"}></use>
                        </svg>
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className={style.inputWrapper}>
              <Field
                type="file"
                placeholder="add photo"
                accept="image/*, .png, .gif, .jpg, .web"
                multiple
                aria-label="choose photos from your device"
                onChange={onChangeFile}
                name="files"
                id="files"
                className={style.inputFiles}
              />
              <label
                id="labelFiles"
                className={style.labelFiles}
                htmlFor="files"
              >
                Bибрати файли
              </label>
            </div>
          </div>

          <Button text="Опублікувати" aria-label="submit news"/>
        </Form>
      )}
    </Formik>
  );
};

export default AdminForm;
