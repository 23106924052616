export default function getDate(str: string | undefined): string {
    if (!str) {
        return '';
    }

    const date = new Date(str);
    
    return date.toLocaleString('uk', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    });
};