import * as Yup from "yup";
// import countries from "../data/countries.json";


export const validationSchemaSimple = Yup.object().shape({
    opinion: Yup.string().max(200, 'Відгук поминен містити не більше 200 символів').min(10, 'Відгук поминен містити щанайменше 10 символів').required("Залиште свій відгук"),
    rate: Yup.number().required("Оцініть, будь ласка, туристичну агенцію").notOneOf([0], 'Оцініть, будь ласка'),
});

export const validationSchema = Yup.object().shape({
    opinion: Yup.string().max(200, 'Відгук поминен містити не більше 200 символів').min(10, 'Відгук поминен містити щанайменше 10 символів').required("Залиште свій відгук"),
    rate: Yup.number().required("Оцініть, будь ласка, туристичну агенцію").notOneOf([0], 'Оцініть, будь ласка'),
    userNameFromInput: Yup.string(),
});

export const validationSchemaHotel = Yup.object().shape({
    hotel: Yup.string().required("Введіть готель").min(4),
    opinion: Yup.string().min(10, 'Відгук поминен містити щанайменше 10 символів').required("Залиште свій відгук"),
    rate: Yup.number().required("Оцініть, будь ласка, вашу подорож").notOneOf([0], 'Оцініть, будь ласка'),
    userNameFromInput: Yup.string(),
    country: Yup.string().min(2).required("Виберіть країну")
});

export const validationSchemaSimpleHotel = Yup.object().shape({
    hotel: Yup.string().required("Введіть готель").min(4),
    opinion: Yup.string().min(10, 'Відгук поминен містити щанайменше 10 символів').required("Залиште свій відгук"),
    rate: Yup.number().required("Оцініть, будь ласка, вашу подорож").notOneOf([0], 'Оцініть, будь ласка'),
    country: Yup.string().min(2).required("Виберіть країну")
});