import React, { useEffect }  from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";


import { addRequest } from "../../redux/requests/operations";
import { selectUserId } from "../../redux/auth/selectors";
import { useAppDispatch } from "../../redux/hooks";
import Button from "../Button";
import { TourFeedbackFormValues } from "./interfaces";
import { validationSchema } from "../../schemas/feedback";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

import style from "./TourFeesbackForm.module.css";
import useAuth from "hooks/useAuth";



const TourFeedbackForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);
  const { user } = useAuth();

  const { name, email, phone } = user;

  const serviceId = "service_luwwmbh";
  const templateId = "template_k0h9t27";

  const handleSubmit = async(
    values: TourFeedbackFormValues,
    { resetForm }: FormikHelpers<TourFeedbackFormValues>
  ) => {
    console.log(values);
    try {
      await dispatch(addRequest(values));
    } catch (error) {
      console.log(error);
    }

    try {
    await emailjs.send(serviceId, templateId,
      {
        name: values.name,
        email: values.email,
        phone: values.phone,
        message: values.question,
      });
      toast.success("Запит відправлено на пошту менеджеру",
        { theme: "colored" }
      );
    } catch (error: any) {
      console.log(error);   
    };
    console.log(values.subscription);
    if (values.subscription) {
      // window.open('https://t.me/tourdream_ko'); 
      window.location.replace("https://t.me/tourdream_ko");
    }
    
    resetForm();
  };

  useEffect(() => emailjs.init("vRB0rSoTnpK6rERae"),[]);
  
  return (
    <Formik
      initialValues={{
        userId: userId || "",
        name: name || "",
        email: email || "",
        phone: phone || "",
        question: "",
        subscription: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
    >
      {({ errors, touched }) => (
        <Form className={style.tourFeedbackForm} autoComplete="off">
          <div className={style.inputWrapper}>
            <div>
              <ErrorMessage className={style.error} name="name" component="p" />
              <Field
                className={`${style.input} ${
                  errors.name && touched.name ? style.input_error : ""
                }`}
                type="text"
                placeholder="Ім'я"
                name="name"
                id="name"
                aria-label="name"
              />
            </div>
            <div>
              <ErrorMessage
                className={style.error}
                name="email"
                component="p"
              />
              <Field
                className={`${style.input} ${
                  errors.email && touched.email ? style.input_error : ""
                }`}
                type="email"
                placeholder="Електронна адреса"
                name="email"
                id="email"
                aria-label="email"
              />
            </div>
            <div className={style.input_wrapper}>
              <ErrorMessage
                name="phone"
                component="p"
                className={style.error}
              />
              <Field
                className={`${style.input} ${
                  errors.phone && touched.phone ? style.input_error : ""
                }`}
                type="tel"
                placeholder="Номер телефону"
                name="phone"
                id="phone"
                aria-label="phone"
              />
            </div>
            <div className={style.input_wrapper}>
              <ErrorMessage
                name="question"
                component="p"
                className={style.error}
              />
              <Field
                as="textarea"
                className={`${style.input} ${
                  errors.question && touched.question ? style.input_error : ""
                } ${style.input_question}`}
                placeholder="Опис питання"
                name="question"
                id="question"
                aria-label="question"
              />
            </div>
            <label className={style.label}>
              <Field 
                type="checkbox" 
                name="subscription" 
                id="subscription" 
                aria-label="sign up for news"
                className={style.check}
              />
              Підписатися на інформаційну розсилку в Telegram
            </label>
          </div>
          <Button text="Підібрати тур" aria-label="submit request"/>
        </Form>
      )}
    </Formik>
  );
};

export default TourFeedbackForm;
