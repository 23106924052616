import React, { useState } from "react";

import NavDropdown from "../NavDropdown";

import style from "./BurgerMenu.module.css";
import svg from "../../images/sprite.svg";


const BurgerMenu: React.FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleBurgerClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };

  return (
    <div className={style.container}>
      <div onClick={handleBurgerClick} className={style.burger} aria-label="burger menu">
        <svg className={style.icon} >
          <use href={svg + '#burger-menu-3'}></use>
        </svg>
      </div>
      {isDropdownVisible && <NavDropdown closeDropdown={closeDropdown}/>}
      {isDropdownVisible && <div className={style.overlay} onClick={()=>closeDropdown()}></div>}
    </div>
  );
};

export default BurgerMenu;