export function formatDate (date: string | number | Date | undefined)  {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };
  if (date) {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
    const [day, month, year] = formattedDate.split('/');
    return `${day}.${month}.${year}`;
  }
  return "unknown date";
};



export function formatDateYearMonth (date: string | number | Date | undefined)  {
  const options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    year: 'numeric',
  };
  if (date) {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
    const [month, year] = formattedDate.split('/');
    return `${month}.${year}`;
  }
  return "unknown date";
};