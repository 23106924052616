import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([^0-9]*)$/gm, "Ввести можливо тільки букви")
      .required("Введіть ваше ім`я"),
    email: Yup.string()
      .email("Не вірна електронна адреса")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Введіть електронну адресу у форматі your@mail.com"
      )
      .required("Необхідно ввести електронну адресу"),
    phone: Yup.string()
      .matches(/^\+380\d{2}\d{7}$/, "Введіть номер телефону у форматі +380501231212")
      .required("Необхідно ввести номер телефону"),
  });

export const validationShemaSimple = Yup.object().shape({
  email: Yup.string()
    .email("Не вірна електронна адреса")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Введіть електронну адресу у форматі your@mail.com"
    )
    .required("Необхідно ввести електронну адресу"),
  password: Yup
    .string()
    .required("Пароль обов'язковий")
    .min(6, 'Пароль має містити як мінімум 6 символів')
    .max(16, 'Пароль має містити максимум 16 символів')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,16}$/,
      'Пароль має містити як мінімум одну велику літеру, одну маленьку літеру та одну цифру'
    )
    .trim(),
})

export const validationSchemaEmail = Yup.object().shape({
  email: Yup.string()
    .email("Не вірна електронна адреса")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Введіть електронну адресу у форматі your@mail.com"
    )
    .required("Необхідно ввести електронну адресу"),
});

export const validationPasswordSimple = Yup.object().shape({
  password: Yup.string()
    .required("Пароль обов'язковий")
    .min(6, 'Пароль має містити як мінімум 6 символів')
    .max(16, 'Пароль має містити максимум 16 символів')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,16}$/,
      'Пароль має містити як мінімум одну велику літеру, одну маленьку літеру та одну цифру'
    )
    .trim(),
  confirmation: Yup.string()
    .required('Підтвердження паролю обов\'язкове')
    .oneOf([Yup.ref('password')], 'Паролі мають співпадати'),
});