export enum OpinionsCategory {
    AGENCY = 'agency',
    HOTELS = 'hotels',
};

export enum NewsCategory {
    AGENCY = 'Agency',
    TOURS = 'Tours',
    HOTELS = 'Hotels',
  }

export interface DeleteType {
    id: string,
    filenames: string[],
};

export type OpinionItemProps<T> = {
    opinion: T;
    onDelete: (dataForDelete: DeleteType) => void;
    isAdmin: boolean;
};

export type NewsItemProps<T> = {
    oneNews: T;
    onDelete: (dataForDelete: DeleteType) => void;
    isAdmin: boolean;
  };

export type OpinionListProps<T> = {
    opinions: T[];
    onDelete: (dataForDelete: DeleteType) => void;
    isAdmin: boolean;
};

export type RequestsListProps<T> = {
    requests: T[];
};

export type RequestsItemProps<T> = {
    request: T;
};

export type UsersListProps<T> = {
    users: T[];
  };

export type UsersItemProps<T> = {
    user: T;
  };

export type ModalProps = {
  onClose: () => void;
};

export interface PhotoObject {
    fileName: string;
    fileBlobUrl: string;
};

export interface Admin {
    isAdmin: boolean;
  }

export interface User {
    id: string | null;
    name: string | null;
    email: string | null;
    phone: string | null;
    isAdmin: boolean;
}

export interface NavDropdownProps {
  closeDropdown: () => void; 
}


