import React from "react";

import {formatDateAndTime} from "../../utils/formatDateAndTime";
import { RequestsItemProps } from "../../interfaces/interfaces";
import { RequestType } from "../RequestsList/interfaces";

import style from "./RequestListItem.module.css";


const RequestListItem: React.FC<RequestsItemProps<RequestType>> = ({ request }) => {
 
  return (
    <li key={request._id} className={style.container}>
      <p className={style.item}><span className={style.category}>Ім'я:</span> {request.name}</p>
      <p className={style.item}><span className={style.category}>Телефон:</span> {request.phone}</p>
      <p className={style.item}><span className={style.category}>Email:</span> {request.email}</p>
      <p className={style.item}><span className={style.category}>Опис:</span> {request.question}</p>
      <p className={style.item}><span className={style.category}>Час створення:</span> 
      {formatDateAndTime(request.createdAt)}
      </p>
    </li>
  );
};

export default RequestListItem;