import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { RootState } from "../store";
import { User } from "../../interfaces/interfaces";
import { ChangingPasswordData } from "../../components/ChangePasswordForm/interfaces";

// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL ='https://hilarious-meta-tourdream.koyeb.app/';

interface RegisterUserPayload extends User {
  token: string;
}

const setAuthHeader = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = '';
};

export const registerUser = createAsyncThunk<RegisterUserPayload, any>(
  'auth/register',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('users/register', credentials);
      setAuthHeader(res.data.token);
       toast.success('Новий користувач успішно створений',
                { theme: 'colored' }
            );
      return res.data;
    } catch (error: any) {
      let message: string = '';
      if (typeof (error.response.data.message) === 'string') {
        message = error.response.data.message
      } else
      {
        message = error.response.data.message[0]
      };
      toast.error(message,
        { theme: 'colored' }
      );
      // console.log('error', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logIn = createAsyncThunk<RegisterUserPayload, any>(
  'auth/login',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post('users/login', credentials);
      // console.log('res in login,', res);
      setAuthHeader(res.data.token);
      toast.success('Авторизація прошла успішно',
                { theme: 'colored' }
            );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message,
         { theme: 'colored' }
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logOut = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    await axios.post('users/logout');
    clearAuthHeader();
  } catch (error: any) {
    toast.error(error.response.data.message,
    { theme: 'colored' }
    );
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const current = createAsyncThunk<any, void, { rejectValue: string }>(
  'auth/current', 
  async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const persistedToken = state.auth.token;

  if (persistedToken === null) {
    return thunkAPI.rejectWithValue('Unable to fetch user');
  }

  try {
    setAuthHeader(persistedToken);
    const res = await axios.get('users/current');
    return res.data;
  } catch (error: any) {
    toast.error(error.message,
      { theme: 'colored' }
    );
    console.log('error current', error)
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateUser = createAsyncThunk<RegisterUserPayload, any>(
  'auth/updateUser',
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.patch('users/update', credentials);
      // setAuthHeader(res.data.token);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message,
        { theme: 'colored' }
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk<RegisterUserPayload, any>(
  'auth/resetPassword',
  async (credentials, thunkAPI) => {
    // console.log('credentials', credentials)
    try {
      const res = await axios.post('users/reset', credentials);
      console.log('res.data resetPassword', res.data)
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message,
        { theme: 'colored' }
      );
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const changePassword = createAsyncThunk<ChangingPasswordData, any>(
  'auth/changePassword',
  async (credentials, thunkAPI) => { 
    // console.log('password', password)
    try {
      const res = await axios.patch('users/changePassword', credentials);
      console.log('res changePassword', res)
       toast.success("Пароль успішно змінено",
        { theme: 'colored' }
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { theme: 'colored' });
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllUsers = createAsyncThunk<any, void, { rejectValue: string }>(
  'auth/users', 
  async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const persistedToken = state.auth.token;

  if (persistedToken === null) {
    return thunkAPI.rejectWithValue('Unable to fetch user');
  }

  try {
    setAuthHeader(persistedToken);
    const res = await axios.get('users');
    return res.data;
  } catch (error: any) {
    toast.error(error.message,
      { theme: 'colored' }
    );
    console.log('error users', error)
    return thunkAPI.rejectWithValue(error.message);
  }
});

