import React from "react";
import { NavLink } from "react-router-dom";

import style from "./Logo.module.css";
import logo from "../../images/logo.svg";


const Logo: React.FC = () => {
  return (
    <NavLink to="/" aria-label="transfer to home page">
      <img className={style.logo} src={logo} alt="logo" />
    </NavLink>
  );
};

export default Logo;