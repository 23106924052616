import React, { useState }  from "react";
import extractFilenameFromUrl from "../../utils/filenameFromUrl";
import createStars from "../../utils/createStars";
import {formatDateYearMonth, formatDate} from "../../utils/formatDate";
import Modal from "../../components/Modal";
import StarComponent from "../../components/StarComponent/StarComponent";
import { OpinionItemProps, OpinionsCategory } from "../../interfaces/interfaces";
import { OpinionType } from "./interfaces";
import style from "./OpinionListItem.module.css";
import sprite from "../../images/sprite.svg"


const OpinionListItem: React.FC<OpinionItemProps<OpinionType>> = ({ opinion, onDelete, isAdmin }) => {
  let rating: number[] = [];
  const onlyFilenames: string[] = [];

  const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);

  const handleImgClick = (index: number) => {
    setSelectedImgIndex(index);
  };

  const handleImgClose = () => {
    setSelectedImgIndex(null);
  };

  if (opinion.photos){
    for (const filename of opinion.photos) {
      onlyFilenames.push(extractFilenameFromUrl(filename));
    }
  }
  
  if (opinion.rate) {
    rating = createStars(opinion.rate);
  }
  
  return (
    <li className={style.container}>
      <div className={style.textWrap}>
        <div className={style.mobWrap}>
          {opinion.category === OpinionsCategory.HOTELS && 
            (
            <div className={style.countryAndHotel}>
              <div className={style.iconWrapper}>
                <svg className={style.iconHotel}>
                  <use href={sprite + '#hotel'}></use>
                </svg> 
                <h3 className={style.hotelName}>      
                  {opinion.hotel}
                </h3>
              </div>
              <div className={style.iconWrapper}>
                <div className={style.iconBg}>
                  <svg className={style.iconFlag} >
                    <use href={sprite + `#${opinion.country}`}></use>
                  </svg>
                </div>

                <h3 className={style.textItem}>
                  {opinion.country} 
                </h3>
              </div>
                  {/* <br />  */}
              
                              
            </div>
            )
          }
          <div className={style.rateWrap}>
            <div className={style.rate}>
              {rating?.map((item) => {
                return(
                  <StarComponent key={item} icon={'#star-rate-filled'}/>
                )
              })}
            </div>
          </div>
        </div>

      </div>
      <div
        className={style.description}
        dangerouslySetInnerHTML={{ __html: opinion.opinion.replace(/\n/g, "<br>") }}
        />
      {opinion.photos && 
        (<ul className={style.photosList}>
          {opinion.photos.map((photo, index) => {
            return (
              <li key={index} onClick={() => handleImgClick(index)}>
                <img src={photo} alt={opinion.hotel} className={style.img} />
              </li>
            )
          })}
        </ul>)
      }
      
      {isAdmin && (
        <button 
          type="button" 
          className={style.btn} 
          onClick={() => onDelete({id: opinion._id, filenames: onlyFilenames})}
          aria-label="delete opinion"
        >
          Видалити
        </button>
      )}

      <div className={style.additional_info}>
        <div className={style.date}>
          <svg className={style.icon} >
            <use href={sprite + '#calendar'}></use>
          </svg>
          <p>
            {opinion.category === OpinionsCategory.HOTELS ? formatDateYearMonth(opinion.dateOfVisit) :
                  formatDate(opinion.updatedAt)}
          </p>
        </div>
        <h4>
          {opinion.userName}
        </h4>
      </div>

      <Modal
        isOpen={selectedImgIndex !== null}
        onClose={handleImgClose}
      >
        {selectedImgIndex !== null && (
          <div className={style.bigPhotoWrapper}>
            <img
              src={opinion.photos[selectedImgIndex]}
              alt={opinion.photos[selectedImgIndex]}
              className={style.bigImg}
            />
          </div>
        )}
      </Modal>
    </li>
  );
};

export default OpinionListItem;