import { ReactNode } from "react";

import style from "./ContainerWrap.module.css";

type Props = {
    children: ReactNode
}


const ContainerWrap: React.FC<Props> = ({children} : Props) => {
    return (
        <div className={style.container}>
            {children}
        </div> 
    )
};

export default ContainerWrap;