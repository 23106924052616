import React from "react";

import style from "./ContactData.module.css";
import svg from "../../images/sprite.svg";


const ContactData: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.contacts_data}>
        <h3 className={style.title}>Наші контакти</h3>
        <div className={style.list_with_image}>
          <svg className={style.phone_icon}>
            <use href={svg + "#contacts"}></use>
          </svg>
          <ul className={style.contact_list}>
            <li>
              <a className={style.contact_item} aria-label="phone to us" href="tel:+380982885050">+38 (098) 288-50-50 </a>
            </li>
            <li>
              <a className={style.contact_item} aria-label="phone to us" href="tel:+380979953453"> +38 (097) 995-34-53</a>
            </li>
            <li>
              <a className={style.contact_item} aria-label="phone to us" href="tel:+380675059619">+38 (067) 505-96-19</a>
            </li>
          </ul>
        </div>
        <div className={style.list_with_image}>
          <svg className={style.phone_icon}>
            <use href={svg + "#mail"}></use>
          </svg>
          <a href="mailto:tourdream@ukr.net" aria-label="write to our email" className={style.contact_item}>
            tourdream@ukr.net
          </a>
        </div>
        <div className={style.list_net_icon}>
          <a href="https://invite.viber.com/?g2=AQA%2Bsi1%2BietW%2Fkhdec4q3wn3b2JvHmyM%2FRfobL2kOguycPvXQhrfRoGHw1C55Zye" 
          target="_blank" rel="noopener noreferrer" aria-label="Viber" className={style.contact_item}>
            <svg className={style.net_icon}>
              <use href={svg + "#viber"}></use>
            </svg>
          </a>
          <a href="https://t.me/tourdream_ko" target="_blank" rel="noopener noreferrer" aria-label="Telegram" className={style.contact_item}>
            <svg className={style.net_icon}>
              <use href={svg + "#telegram"}></use>
            </svg>
          </a>
        </div>
      </div>
      <div className={style.shedule_and_address}>
        <div className={style.contacts_data}>
          <h3 className={style.title}>Графік роботи</h3>
          <div className={style.list_with_image}>
            <svg className={style.phone_icon}>
              <use href={svg + "#schedule"}></use>
            </svg>
            <ul className={style.contact_list}>
              <li className={style.contact_item}>
                Понеділок - П'ятниця - 10:00 - 19:00
              </li>
              <li className={style.contact_item}>Субота - 10:00 - 15:00</li>
              <li className={style.contact_item}>Неділя - за домовленістю</li>
            </ul>
          </div>
        </div>
        <div className={style.contacts_data}>
          <h3 className={style.title}>Адреса</h3>
          <div className={style.list_with_image}>
            <svg className={style.phone_icon}>
              <use href={svg + "#location"}></use>
            </svg>
            <p className={style.contact_item}>
              м. Київ <br />
              на данний момент <br />
              працюємо у режимі онлайн
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactData;
